import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../../Components/Contexts/AuthContext'
import { Link } from 'react-router-dom'
import NAutorizado from '../../imagens/nautorizado.png'
import './dashboradRoot.estilo.scss'


export default function DashboardRootAdm() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const [coordenador, setCoordenador] = useState('')
    const [grupo, setGrupo] = useState('')


    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                const grupoT = localStorage.getItem('@GrupoNome')
                setCoordenador(JSON.parse(cordT))
                setGrupo(JSON.parse(grupoT))
            }
            buscaDados()
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <div className='conteinerDashboardRootGeral'>
                {coordenador === false || (grupo !== 'ROOT') ?
                    <img src={NAutorizado} alt="" />
                    :
                    <div className='tituloDashboardRoot'>
                        <h1>Dashboard Manutenção Acampados</h1>
                        <div className='botoes'>
                            <Link to='/RelatorioIncra'>Relatório Incra</Link>
                            <Link to='/GerenciaRoot'>Gerencia</Link>
                            <Link to='/VistoriaRoot'>Vistoria</Link>
                            <Link to='/SolicitacaoVistoriaRoot'>Solicitação</Link>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../../../Components/Contexts/AuthContext'
import apiLocal from '../../../apiLocal/apiLocal'
import NAutorizado from '../../../imagens/nautorizado.png'
import Bandeira from '../../../imagens/BANDEIRA_MNL.png'
import './relatorioIncra.estilo.scss'

import generatePDF, { Resolution, Margin } from 'react-to-pdf'

const personalizacao = {
    method: 'open',
    resolution: Resolution.HIGH,
    page: {
        margin: Margin.SMALL,
        format: 'A4',
        orientation: 'portrait',
        //orientation: 'landscape',
    },
    overrides: {
        pdf: {
            compress: true
        },
    },
}

const recuperarConteudoPDF = () => document.getElementById('conteudo')

export default function RelatorioIncra() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()
    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)

    const [coordenador, setCoordenador] = useState('')
    const [grupo, setGrupo] = useState('')

    const [dados, setDados] = useState([''])


    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                const grupoT = localStorage.getItem('@GrupoNome')
                setCoordenador(JSON.parse(cordT))
                setGrupo(JSON.parse(grupoT))
            }
            buscaDados()
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [dados])

    async function solicitarRelatorio() {

        try {
            const resposta = await apiLocal.get('/ListarAcampadosIncra', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setDados(resposta.data)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div>
            <div className='conteinerRelatorioIncraRootGeral'>
                {coordenador === false || (grupo !== 'ROOT') ?
                    <img src={NAutorizado} alt="" />
                    :
                    <>
                        <h1>Relatório Terra Prometida para o Incra</h1>
                        <button onClick={solicitarRelatorio}>Tirar Relatorio</button>
                        <button onClick={() => generatePDF(recuperarConteudoPDF, personalizacao)}>Download PDF</button>
                        <div id='conteudo'>
                            <div className='cabRelatorioIncra'>
                                <img src={Bandeira} alt="" />
                                <h1>Relatório Terra Prometida Famílias Ativas</h1>
                            </div>
                            {dados.length !== 1 && (
                                <>
                                    {dados.map((item) => {
                                        return (
                                            <>
                                                <table className='tabelaRelatorioRoot'>
                                                    <thead>
                                                        <tr key="">
                                                            <th>
                                                                <div className='tituloRelatorioIncraRootGeral'>
                                                                    <div className='blocosRelatodioTitular'>
                                                                        <h2>Titular</h2>
                                                                        <span>Nome: {item.nome}</span>
                                                                        <span>CPF: {item.cpf}</span>
                                                                        <span>RG: {item.rg}</span>
                                                                        <span>Nis: {item.nis}</span>
                                                                        <span>Celular: {item.celular}</span>
                                                                    </div>
                                                                    <div className='blocosRelatodioTitular'>
                                                                        {item.conjuge != null && (
                                                                            <>
                                                                                <h2>Conjuge</h2>
                                                                                <span>Nome: {item.conjuge.nome}</span>
                                                                                <span>CPF: {item.conjuge.cpf}</span>
                                                                                <span>RG: {item.conjuge.rg}</span>
                                                                                <span>Nis: {item.conjuge.nis}</span>
                                                                                <span>Celular: {item.conjuge.celular}</span>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </>
                                        )
                                    })}
                                </>
                            )}
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../../../Components/Contexts/AuthContext'
import { Link } from 'react-router-dom'
import apiLocal from '../../../apiLocal/apiLocal'
import './dadosAcampadoPendente.estilo.scss'
import NAutorizado from '../../../imagens/nautorizado.png'

import { IMaskInput } from 'react-imask'


export default function AcampadoPendente() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const [acamapdosPendente, setAcampadosPendente] = useState([''])

    const [coordenador, setCoordenador] = useState('')
    const [grupo, setGrupo] = useState('')

    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)

    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                const grupoT = localStorage.getItem('@GrupoNome')
                setCoordenador(JSON.parse(cordT))
                setGrupo(JSON.parse(grupoT))
            }
            buscaDados()
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        try {
            async function listarAcampados() {
                const resposta = await apiLocal.get('/ListarAcampadosPendente', {
                    headers: {
                        // eslint-disable-next-line
                        Authorization: `Bearer ${token}`
                    }
                })
                setAcampadosPendente(resposta.data)
            }
            listarAcampados()
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <div className='containerGeralDadosAcampadosPendente'>
                {coordenador === false || (grupo !== 'ADM' && grupo !== 'ROOT') ?
                    <img src={NAutorizado} alt="" />
                    :
                    <div>
                        <div className='tituloDadosAcampadosPendente'>
                            <h1>Acampados Pendentes</h1>
                        </div>
                        <div className='tabelaDadosAcampadosPendente'>
                            <table className='dadosTabelasPendente'>
                                <thead>
                                    <tr key="">
                                        <th>Nome Titular</th>
                                        <th>CPF Titular</th>
                                        <th>Situação</th>
                                        <th>Data Cadastro</th>
                                        <th>Conferencia</th>
                                    </tr>
                                    {acamapdosPendente.map((item) => {
                                        const formatDateToBrazilian = (isoDate) => {
                                            const date = new Date(isoDate);
                                            return date.toLocaleDateString('pt-BR');
                                        }
                                        const createDate = item.create_at
                                        return (
                                            <tr key={item.id}>
                                                <>
                                                    <td>{item.nome}</td>
                                                    <td>
                                                        <IMaskInput
                                                            disabled
                                                            mask='000.xxx.xxx-00'
                                                            value={item.cpf}
                                                        />
                                                    </td>
                                                    <td>{item.status}</td>
                                                    <td>{formatDateToBrazilian(createDate)}</td>
                                                    <td><Link to={`/ConferirAcampado/${item.id}`}>Conferir</Link></td>
                                                </>
                                            </tr>
                                        )
                                    })}
                                </thead>
                            </table>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
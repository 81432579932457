import React, { useContext } from 'react'
import { AuthContexts } from '../../Components/Contexts/AuthContext'
import { Link } from 'react-router-dom'
import './doacoesDinheiro.estilo.scss'


export default function DoacoesDinheiro() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    return (
        <div className='conteinerGeralDoacaoesDinheiro'>
            <h1>Doações em Dinheiro</h1>
            <div className='conteinerBotoesDoacaoDinheiro'>
                <Link to='/DoarDinheiro'>Doar</Link>
            </div>
        </div>
    )
}
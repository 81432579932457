import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../Components/Contexts/AuthContext'
import { Link } from 'react-router-dom'
import apiLocal from '../apiLocal/apiLocal'

import './dashboardAcampado.estilo.scss'
import { toast } from 'react-toastify'

export default function Dashboard() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()
    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)
    const iId = localStorage.getItem('@Id')
    const id = JSON.parse(iId)

    const [nome, setNome] = useState('')
    const [status, setStatus] = useState('')
    const [rua, setRua] = useState('')
    const [rancho, setRancho] = useState('')
    const [pontuacao, setPontuacao] = useState('')
    const [estadoCivil, setEstadoCivil] = useState('')
    const [incra, setIncra] = useState('')
    const [idPais, setIdPais] = useState('')

    const [conjugeExiste, setConjugeExiste] = useState(false)
    const [conjuge, setConjuge] = useState('')
    const [filhos, setFilhos] = useState([''])
    const [dados, setDados] = useState([''])
    const [dadosDoacao, setDadosDoacao] = useState([''])

    useEffect(() => {
        try {
            async function buscaDados() {
                const idPaisT = localStorage.getItem('@Id')
                const nomeT = localStorage.getItem('@Nome')
                const statusT = localStorage.getItem('@Status')
                const ruaT = localStorage.getItem('@Rua')
                const ranchoT = localStorage.getItem('@Rancho')
                const pontuacaoT = localStorage.getItem('@Pontuacao')
                const estadoCivilT = localStorage.getItem('@EstadoCivil')
                const incraT = localStorage.getItem('@Incra')
                setNome(JSON.parse(nomeT))
                setStatus(JSON.parse(statusT))
                setRua(JSON.parse(ruaT))
                setRancho(JSON.parse(ranchoT))
                setPontuacao(JSON.parse(pontuacaoT))
                setEstadoCivil(JSON.parse(estadoCivilT))
                setIncra(JSON.parse(incraT))
                setIdPais(JSON.parse(idPaisT))
            }
            buscaDados()
        } catch (err) {
            toast.error('Erro ao comunicar com o Servidor')
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        try {
            async function listarSolicitacoes() {
                const resposta = await apiLocal.get('/ListarSolicitacoesGeral', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                setDados(resposta.data)
            }
            listarSolicitacoes()
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        try {
            async function buscaConjuge() {
                const resposta = await apiLocal.get('/VerDadosAcampadosConjuge', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                setConjuge(resposta.data)
                if (resposta.data === null) {
                    setConjugeExiste(false)
                } else {
                    setConjugeExiste(true)
                }
            }
            buscaConjuge()
        } catch (err) {
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function buscarFilhos() {
            const resposta = await apiLocal.get('/VerDadosFilhosMenores', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setFilhos(resposta.data)
        }
        buscarFilhos()
        // eslint-disable-next-line
    }, [])

    async function solicitarVistoria() {
        const idT = localStorage.getItem('@Id')
        const titularId = JSON.parse(idT)
        try {
            const resposta = await apiLocal.post('/CadastroAgendamentoVistorias', {
                titularId
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            toast.success(resposta.data.dados, {
                toastId: 'ToastId'
            })
        } catch (err) {
            toast.error(err.response.data.error, {
                toastId: 'ToastId'
            })
        }
    }

    useEffect(() => {
        async function listarDoacoesDinheiro() {
            const resposta = await apiLocal.get('/ListarDoacaoDinheiro', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setDadosDoacao(resposta.data)
        }
        listarDoacoesDinheiro()
        // eslint-disable-next-line
    }, [dadosDoacao])
    //}, [])

    return (
        <div>
            <div className='conteinerGeralDasboardAcampado'>
                <div className='tituloDashboardAcampado'>
                    <h1>Dashboard Acampados</h1>
                </div>
                <div className='dadosDashboardAcampados'>
                    <h2>Dados dos Acampados</h2>
                    <Link to='/VisualizarDadosAcampado'>Visualizar Dados - Alterar Senha</Link>
                    {dados.length !== 1 && (
                        <>
                            {status === 'Pendente' && (
                                <>
                                    {rua !== null && (
                                        <>
                                            <button onClick={solicitarVistoria}>Solicitar Vistoria</button>
                                            <span>Data de agendadamento da Vistoria: {dados.map((item) => {
                                                const formatDateToBrazilian = (isoDate) => {
                                                    const date = new Date(isoDate);
                                                    return date.toLocaleDateString('pt-BR');
                                                }
                                                const createDate = item.data
                                                //const data = formatDateToBrazilian(createDate)
                                                return (
                                                    <>
                                                        {createDate !== null && (
                                                            <>
                                                                {(id === item.solicitacaovistoria.id) && (
                                                                    <>
                                                                        {formatDateToBrazilian(createDate)}
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )
                                            })}
                                            </span>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    <table className='dadosTabelas'>
                        <thead>
                            <tr key="">
                                <th>Nome do Acampado</th>
                                <th>Situação do Acampado</th>
                                <th>Numero da Rua</th>
                                <th>Numero do Rancho</th>
                                <th>Incra</th>
                                <th>Pontuação</th>
                            </tr>
                            <tr key={id}>
                                <td>{nome.toLocaleUpperCase()}</td>
                                {status === 'Ativo' && (
                                    <td className='corStatusAcampadoAtivo'>{status}</td>
                                )}
                                {status === 'Pendente' && (
                                    <td className='corStatusAcampadoPendente'>{status}</td>
                                )}
                                {status === 'Inativo' && (
                                    <td className='corStatusAcampadoInativo'>{status}</td>
                                )}
                                {status === 'Indefinido' && (
                                    <td>Indefinido</td>
                                )}
                                {rua === null || rua === ' ' ? <td>Não Informado</td> : <td>{rua}</td>}
                                {rancho === null || rancho === ' ' ? <td>Não Informado</td> : <td>{rancho}</td>}
                                {incra === 'Não' ? <td className='corStatusAcampadoInativo'>{incra}</td> : <td className='corStatusAcampadoAtivo'>{incra}</td>}
                                <td>{pontuacao}</td>
                            </tr>
                        </thead>
                    </table>
                </div>
                {(estadoCivil === 'Casado' || estadoCivil === 'União Estável') ?
                    <div className='dadosDashboardAcampados'>
                        <h2>Dados Conjuge</h2>
                        {conjugeExiste === false && (
                            <Link to='/CadastroAcampadosConjuge'>Cadastro Conjuge</Link>
                        )}
                        <table className='dadosTabelas'>
                            <thead>
                                <tr key="">
                                    <th>Nome</th>
                                </tr>
                                <tr key="">
                                    {conjugeExiste === false ? <td></td> : <td>{conjuge.nome.toLocaleUpperCase()}</td>}
                                </tr>
                            </thead>
                        </table>
                    </div>
                    :
                    <div></div>
                }
                <div className='dadosDashboardAcampados'>
                    <h2>Dados Filhos</h2>
                    <Link to='/CadastroAcampadosFilhos'>Cadastro Filhos</Link>
                    <table className='dadosTabelas'>
                        <thead>
                            <tr key="">
                                <th>Nome</th>
                            </tr>
                            {filhos.length === 0 ?
                                <tr key=""><td></td></tr>
                                :
                                <>
                                    {filhos.map((item) => {
                                        return (
                                            <>
                                                {item.paisId === idPais && (
                                                    <tr key={item.id}>
                                                        <td>{item.nome}</td>
                                                    </tr>
                                                )}
                                            </>
                                        )
                                    })}
                                </>
                            }
                        </thead>
                    </table>
                </div>
                <div className='dadosDashboardAcampados'>
                    <h2>Doações Voluntárias em Dinheiro</h2>
                    <table className='dadosTabelas'>
                        <thead>
                            <tr key="">
                                <th>Data Doação</th>
                                <th>Valor Doado</th>
                                <th>Finalidade</th>
                                <th>Conferido</th>
                                <th>Ação</th>
                            </tr>
                            {dadosDoacao.map((item) => {
                                const formatDateToBrazilian = (isoDate) => {
                                    const date = new Date(isoDate);
                                    return date.toLocaleDateString('pt-BR');
                                }
                                const createDate = item.dataDoacao
                                return (
                                    <>
                                        <tr key={item.id}>
                                            {item.titularId === id && (
                                                <>
                                                    <td>{formatDateToBrazilian(createDate)}</td>
                                                    <td>R$ {item.valorDoacao.replace(".", ",")}</td>
                                                    <td>{item.finalidadeDoacao}</td>
                                                    {item.status === false ? <td>Não</td> : <td>Sim</td>}
                                                    {item.status === true ?
                                                        <td></td>
                                                        :
                                                        <td><Link to={`/EditarDoacoesDinheiro/${item.id}`} >Editar</Link></td>
                                                    }
                                                </>
                                            )}
                                        </tr >
                                    </>
                                )
                            })}
                        </thead>
                    </table>
                </div>
                <div className='dadosDashboardAcampados'>
                    <h2>Doações Voluntárias em Alimento</h2>
                    <table className='dadosTabelas'>
                        <thead>
                            <tr key="">
                                <th>Data Doação</th>
                                <th>Alimento Doado</th>
                                <th>Situação Doação</th>
                            </tr>
                            <tr key="">

                            </tr>
                        </thead>
                    </table>
                </div>
                <div className='dadosDashboardAcampados'>
                    <h2>Doações Voluntárias em Roupas</h2>
                    <table className='dadosTabelas'>
                        <thead>
                            <tr key="">
                                <th>Data Doação</th>
                                <th>Roupa Doada</th>
                                <th>Situação Doação</th>
                            </tr>
                            <tr key="">

                            </tr>
                        </thead>
                    </table>
                </div>
                <div className='dadosDashboardAcampados'>
                    <h2>Trabalhos Voluntários no Acampamento</h2>
                    <table className='dadosTabelas'>
                        <thead>
                            <tr key="">
                                <th>Data Trabalho</th>
                                <th>Tipo Trabalho</th>
                                <th>Situação Trabalho</th>
                            </tr>
                            <tr key="">

                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div >
    )
}
import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../../../Components/Contexts/AuthContext'
import apiLocal from '../../../apiLocal/apiLocal'
import Modal from 'react-modal'
import NAutorizado from '../../../imagens/nautorizado.png'
import generatePDF, { Resolution, Margin } from 'react-to-pdf'

import './solicitarVistoria.estilo.scss'
import { toast } from 'react-toastify'

const personalizacao = {
    method: 'open',
    resolution: Resolution.HIGH,
    page: {
        margin: Margin.SMALL,
        format: 'A4',
        orientation: 'landscape',
    },
    overrides: {
        pdf: {
            compress: true
        },
    },
}

const recuperarConteudoPDF = () => document.getElementById('conteudo')

export default function SolicitacaoVistoria() {
    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)

    const [coordenador, setCoordenador] = useState('')
    const [grupo, setGrupo] = useState('')
    const [id, setId] = useState('')

    const [dados, setDados] = useState([''])
    const [dataAgendamento, setDataAgendamento] = useState('')
    const [loading, setLoading] = useState(false)
    const [modalAberto, setModalAberto] = useState(false)
    const [modalAbertoPDF, setModalAbertoPDF] = useState(false)

    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                const grupoT = localStorage.getItem('@GrupoNome')
                setCoordenador(JSON.parse(cordT))
                setGrupo(JSON.parse(grupoT))
            }
            buscaDados()
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        try {
            async function listarSolicitacoes() {
                const resposta = await apiLocal.get('/ListarSolicitacoesGeral', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                setDados(resposta.data)
                setLoading(true)
            }
            listarSolicitacoes()
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [dados])

    async function abriModal(idT) {
        setModalAberto(true)
        setId(idT)
    }


    async function fecharModal() {
        setModalAberto(false)
    }

    async function abriModalPDF() {
        setModalAbertoPDF(true)
    }

    async function fecharModalPDF() {
        setModalAbertoPDF(false)
    }

    async function enviarDataAgendamento(e) {
        e.preventDefault()
        try {
            await apiLocal.post('/AgendamentoDataVistoria', {
                dataAgendamento,
                id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setModalAberto(false)
        } catch (err) {

        }
    }

    async function limparAgenda(idT) {
        try {
            const id = idT
            await apiLocal.put('/LimparAgendamentoSolicitacoesGeral', {
                id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            toast.success('Atualizado com Sucesso', {
                toastId: 'ToastId'
            })
        } catch (err) {
            console.log(err)

        }
    }


    if (loading === false) {
        return (
            <div>
                <h1>Carregando...</h1>
            </div>
        )
    } else {
        return (
            <div>
                <div className='conteinerSolicitacaoVistoriaRootGeral'>
                    {coordenador === false || (grupo !== 'ROOT' && grupo !== 'ADM') ?
                        <img src={NAutorizado} alt="" />
                        :
                        <div className='tituloSolicitacaoVistoriaRoot'>
                            <h1>Solicitação Vistoria Root</h1>
                            <button onClick={() => abriModalPDF()}>Gerar Relatório</button>
                            <table className='tabelaSolicitacaoVistoriaRoot'>
                                <thead>
                                    <tr key="id">
                                        <th>Nome</th>
                                        <th>Rua</th>
                                        <th>Rancho</th>
                                        <th>Agendamento</th>
                                        <th>Ação</th>
                                    </tr>
                                    {dados.map((item) => {
                                        const formatDateToBrazilian = (isoDate) => {
                                            const date = new Date(isoDate);
                                            return date.toLocaleDateString('pt-BR');
                                        }
                                        const createDate = item.data
                                        return (
                                            <tr key={item.id}>
                                                <>
                                                    <td>{item.solicitacaovistoria.nome}</td>
                                                    <td>{item.solicitacaovistoria.rua}</td>
                                                    <td>{item.solicitacaovistoria.numeroRancho}</td>
                                                    <td>{createDate !== null && (
                                                        <>
                                                            {formatDateToBrazilian(createDate)}
                                                        </>
                                                    )}</td>
                                                    <td>
                                                        <button className='botaoAgendar' onClick={() => abriModal(item.id)} >Agendar</button>
                                                        <button className='botaoLimpar' onClick={() => limparAgenda(item.id)} type='submit'>Limpar</button>
                                                    </td>
                                                </>
                                            </tr>
                                        )
                                    })}
                                </thead>
                            </table>
                            <Modal
                                className="Modal"
                                isOpen={modalAberto}
                            >
                                <div className='conteinerModal'>
                                    <h1>Agendar Data</h1>
                                    <form onSubmit={enviarDataAgendamento}>
                                        <input
                                            type="datetime-local"
                                            value={dataAgendamento}
                                            onChange={(e) => setDataAgendamento(e.target.value)}
                                        />
                                        <button type='submit'>Enviar</button>
                                    </form>
                                    <button className='botaoSairModal' onClick={fecharModal}>Fechar</button>
                                </div>
                            </Modal>
                            <Modal
                                // className="Modal"
                                isOpen={modalAbertoPDF}
                            >
                                <div>
                                    <button className='botaoPDF' onClick={() => generatePDF(recuperarConteudoPDF, personalizacao)}>Download PDF</button>
                                    <button className='botaoPDFSair' onClick={fecharModalPDF}>Fechar</button>
                                    <div id='conteudo'>
                                        <div className='tituloSolicitacaoVistoriaRootPDF'>
                                            <h1>Relatório Agendamento</h1>
                                            <table className='tabelaSolicitacaoVistoriaRootPDF'>
                                                <thead>
                                                    <tr key="id">
                                                        <th>Nome</th>
                                                        <th>Rua</th>
                                                        <th>Rancho</th>
                                                        <th>Situação</th>
                                                    </tr>
                                                    {dados.map((item) => {
                                                        return (
                                                            <>
                                                                {item.data !== null && (
                                                                    <tr key={item.id}>
                                                                        <>
                                                                            <td>{item.solicitacaovistoria.nome}</td>
                                                                            <td>{item.solicitacaovistoria.rua}</td>
                                                                            <td>{item.solicitacaovistoria.numeroRancho}</td>
                                                                            <td>      </td>
                                                                        </>
                                                                    </tr>
                                                                )}
                                                            </>
                                                        )
                                                    })}
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    }
                </div>
            </div>
        )
    }

}
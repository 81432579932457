import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../../../Components/Contexts/AuthContext'
import apiLocal from '../../../apiLocal/apiLocal'
import NAutorizado from '../../../imagens/nautorizado.png'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import './ruasRanchos.estilo.scss'

export default function RuasRanchos() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const mudarTela = useNavigate()

    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)

    const [coordenador, setCoordenador] = useState('')
    const [grupo, setGrupo] = useState('')

    const [dados, setDados] = useState([''])

    const [id, setId] = useState('')
    const [rua, setRua] = useState('')
    const [numeroRancho, setNumeroRancho] = useState('')


    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                const grupoT = localStorage.getItem('@GrupoNome')
                setCoordenador(JSON.parse(cordT))
                setGrupo(JSON.parse(grupoT))
            }
            buscaDados()
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function listarAcampados() {
            const resposta = await apiLocal.get('/ListarAcampadosRuasRanchos', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setDados(resposta.data)
        }
        listarAcampados()
        // eslint-disable-next-line
    }, [])

    async function enviarDadosRuasRanchos(e) {
        e.preventDefault()
        try {
            const resposta = await apiLocal.put('/InformaRuasRanchos', {
                id,
                rua,
                numeroRancho
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            toast.success(resposta.data.dados, {
                toastId: 'ToastID'
            })
            mudarTela('/CoordAdministracao')
        } catch (err) {
            toast.error(err.response.data.error, {
                toastId: 'ToastId'
            })

        }
    }

    return (
        <div>
            <div className='containerGeralRuasRanchos'>
                {coordenador === false || (grupo !== 'ADM' && grupo !== 'ROOT') ?
                    <img src={NAutorizado} alt="" />
                    :
                    <>
                        <div className='containerTituloRuasRanchos'>
                            <h1>Ruas e Ranchos</h1>
                        </div>
                        <div className='conteinerFormularioRuasRanchos'>
                            <form onSubmit={enviarDadosRuasRanchos}>
                                <select value={id} onChange={(e) => setId(e.target.value)}>
                                    <option>Selecione...</option>
                                    {dados.map((item) => {
                                        return (
                                            <option value={item.id} key={item.id}>{item.nome}</option>
                                        )
                                    })}
                                </select>
                                <input
                                    type="text"
                                    placeholder='Digite o número da Rua'
                                    value={rua}
                                    onChange={(e) => setRua(e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder='Digite o número do Rancho'
                                    value={numeroRancho}
                                    onChange={(e) => setNumeroRancho(e.target.value)}
                                />
                                <button>Enviar</button>
                            </form>

                        </div>
                    </>
                }
            </div>
        </div>
    )
}
import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../../../Components/Contexts/AuthContext'
import { useParams, useNavigate } from 'react-router-dom'
import apiLocal from '../../../apiLocal/apiLocal'
import './conferirDadosAcampado.estilo.scss'
import NAutorizado from '../../../imagens/nautorizado.png'

import { IMaskInput } from 'react-imask'
import { toast } from 'react-toastify'

export default function ConferirAcampado() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const mudarTela = useNavigate()

    const [coordenador, setCoordenador] = useState('')
    const [grupo, setGrupo] = useState('')
    const [dados, setDados] = useState('')

    const { id } = useParams()

    const [nomeTitular, setNomeTitular] = useState('')
    const [rgTitular, setRgTitular] = useState('')
    const [nisTitular, setNisTitular] = useState('')
    const [cadTitular, setCadTitular] = useState('')
    const [tituloTitular, setTituloTitular] = useState('')
    const [celularTitular, setCelularTitular] = useState('')
    const [estadoCivil, setEstadoCivil] = useState('')
    const [estadoCivilNome, setEstadoCivilNome] = useState('')

    const [nomeConjuge, setNomeConjuge] = useState('')
    const [rgConjuge, setRgConjuge] = useState('')
    const [nisConjuge, setNisConjuge] = useState('')
    const [cadConjuge, setCadConjuge] = useState('')
    const [tituloConjuge, setTituloConjuge] = useState('')
    const [celularConjuge, setCelularConjuge] = useState('')

    const [conferidoDoc, setConferidoDoc] = useState('')

    const [verEstadoCivil, setVerEstadoCivil] = useState('')


    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)

    useEffect(() => {
        try {
            async function verDadosEstadoCivil() {
                const respostaec = await apiLocal.get('/VerEstadoCivil')
                setVerEstadoCivil(respostaec.data)
            }
            verDadosEstadoCivil()
        } catch (error) {

        }
    }, [])

    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                const grupoT = localStorage.getItem('@GrupoNome')
                setCoordenador(JSON.parse(cordT))
                setGrupo(JSON.parse(grupoT))
            }
            buscaDados()
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        try {
            async function listarAcampados() {
                const resposta = await apiLocal.post('/ListarAcampadosConferir', {
                    id
                }, {
                    headers: {
                        // eslint-disable-next-line
                        Authorization: `Bearer ${token}`
                    }
                })
                setDados(resposta.data)
                setNomeTitular(resposta.data.nome.toLocaleUpperCase())
                setRgTitular(resposta.data.rg)
                setNisTitular(resposta.data.nis)
                setCadTitular(resposta.data.cadunico)
                setTituloTitular(resposta.data.tituloeleitor)
                setCelularTitular(resposta.data.celular)
                setEstadoCivil(resposta.data.acampadosEC.id)
                setEstadoCivilNome(resposta.data.acampadosEC.nome)

                if (resposta.data.conjuge !== null) {
                    setNomeConjuge(resposta.data.conjuge.nome.toLocaleUpperCase())
                    setRgConjuge(resposta.data.conjuge.rg)
                    setNisConjuge(resposta.data.conjuge.nis)
                    setCadConjuge(resposta.data.conjuge.cadunico)
                    setTituloConjuge(resposta.data.conjuge.tituloeleitor)
                    setCelularConjuge(resposta.data.conjuge.celular)
                }
            }
            listarAcampados()
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [])

    async function conferirDocAcampado(e) {
        e.preventDefault()
        try {
            await apiLocal.put('/ConferidoDocAcampado', {
                id,
                conferidoDoc
            }, {
                headers: {
                    // eslint-disable-next-line
                    Authorization: `Bearer ${token}`
                }
            })
            mudarTela('/DashboardCoordenadores')
        } catch (err) {
            toast.error(err.response.data.error)
        }
    }

    async function alterarTitular() {
        const nome = nomeTitular.toLocaleLowerCase()
        const rg = rgTitular.match(/\d/g).join("")
        const nis = nisTitular.match(/\d/g).join("")
        const cadunico = cadTitular.match(/\d/g).join("")
        const tituloeleitor = tituloTitular.match(/\d/g).join("")
        const celular = celularTitular.match(/\d/g).join("")
        const estado_civilId = estadoCivil
        try {
            await apiLocal.put('/EditarAcampadoTitular', {
                id,
                nome,
                rg,
                nis,
                cadunico,
                tituloeleitor,
                celular,
                estado_civilId
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        } catch (err) {

        }
    }

    async function alterarConjuge() {
        const nome = nomeConjuge.toLocaleLowerCase()
        const rg = rgConjuge.match(/\d/g).join("")
        const nis = nisConjuge.match(/\d/g).join("")
        const cadunico = cadConjuge.match(/\d/g).join("")
        const tituloeleitor = tituloConjuge.match(/\d/g).join("")
        const celular = celularConjuge.match(/\d/g).join("")
        try {
            await apiLocal.put('/EditarAcampadoConjuge', {
                id,
                nome,
                rg,
                nis,
                cadunico,
                tituloeleitor,
                celular
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        } catch (err) {

        }
    }

    if (dados.acampadosEC === undefined) {
        return (
            <div><h1>Carregando</h1></div>
        )
    } else {
        return (
            <div>
                <div className='containerGeralConferiDadosAcampados'>
                    {coordenador === false || (grupo !== 'ADM' && grupo !== 'ROOT') ?
                        <img src={NAutorizado} alt="" />
                        :
                        <>
                            {(dados.acampadosEC.nome === 'União Estável' || dados.acampadosEC.nome === 'Casado') && dados.conjuge === null ?
                                <h2>Os Dados do Conjuge Não Foram Preenchidos</h2>
                                :
                                <div>
                                    <div className='tituloConferirDadosAcampados'>
                                        <h1>Conferência de Acampados</h1>
                                    </div>
                                    <div className='blocosAtivarDadosAcampados'>
                                        <div className='blocosTitular'>
                                            <h1>Titular</h1>
                                            <span>
                                                CPF: <IMaskInput
                                                    disabled
                                                    type="text"
                                                    mask='000.000.000-00'
                                                    value={dados.cpf} />
                                            </span>
                                            <form onSubmit={alterarTitular}>
                                                <span>Nome:
                                                    <input
                                                        type="text"
                                                        value={nomeTitular}
                                                        onChange={(e) => setNomeTitular(e.target.value)}
                                                    /></span>
                                                <span>RG:
                                                    <input
                                                        type="text"
                                                        value={rgTitular}
                                                        onChange={(e) => setRgTitular(e.target.value)}
                                                    /></span>
                                                <span>NIS:
                                                    <input
                                                        type="text"
                                                        value={nisTitular}
                                                        onChange={(e) => setNisTitular(e.target.value)}
                                                    /></span>
                                                <span>Cad. Único:
                                                    <input
                                                        type="text"
                                                        value={cadTitular}
                                                        onChange={(e) => setCadTitular(e.target.value)}
                                                    /></span>
                                                <span>Titulo Eleitor:
                                                    <input
                                                        type="text"
                                                        value={tituloTitular}
                                                        onChange={(e) => setTituloTitular(e.target.value)}
                                                    /></span>
                                                <span>
                                                    Celular: <IMaskInput
                                                        type="text"
                                                        mask='(00) 00000-0000'
                                                        value={celularTitular}
                                                        onChange={(e) => setCelularTitular(e.target.value)}
                                                    />
                                                </span>
                                                {dados.acampadosEC.nome === 'Divorciado' || dados.acampadosEC.nome === 'Solteiro' ?
                                                    <span>Estado Civil:
                                                        <select
                                                            value={estadoCivil}
                                                            onChange={(e) => setEstadoCivil(e.target.value)}
                                                        >
                                                            <option value="" key=""> {estadoCivilNome}</option>
                                                            {verEstadoCivil.map((item) => {
                                                                return (
                                                                    <option value={item.id} key="">{item.nome}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </span> :
                                                    <span></span>
                                                }
                                                <button>Alterar Titular</button>
                                            </form>
                                        </div>
                                        {dados.conjuge != null && (
                                            <div className='blocosTitular'>
                                                <h1>Conjuge</h1>
                                                <span>
                                                    CPF: <IMaskInput
                                                        disabled
                                                        type="text"
                                                        mask='000.000.000-00'
                                                        value={dados.conjuge.cpf} />
                                                </span>
                                                <form onSubmit={alterarConjuge}>
                                                    <span>Nome:
                                                        <input
                                                            type="text"
                                                            value={nomeConjuge}
                                                            onChange={(e) => setNomeConjuge(e.target.value)}
                                                        /></span>
                                                    <span>RG:
                                                        <input
                                                            type="text"
                                                            value={rgConjuge}
                                                            onChange={(e) => setRgConjuge(e.target.value)}
                                                        /></span>
                                                    <span>NIS:
                                                        <input
                                                            type="text"
                                                            value={nisConjuge}
                                                            onChange={(e) => setNisConjuge(e.target.value)}
                                                        /></span>
                                                    <span>Cad. Único:
                                                        <input
                                                            type="text"
                                                            value={cadConjuge}
                                                            onChange={(e) => setCadConjuge(e.target.value)}
                                                        /></span>
                                                    <span>Titulo Eleitor:
                                                        <input
                                                            type="text"
                                                            value={tituloConjuge}
                                                            onChange={(e) => setTituloConjuge(e.target.value)}
                                                        /></span>
                                                    <span>
                                                        Celular: <IMaskInput
                                                            type="text"
                                                            mask='(00) 00000-0000'
                                                            value={celularConjuge}
                                                            onChange={(e) => setCelularConjuge(e.target.value)}
                                                        />
                                                    </span>
                                                    <button>Alterar Conjuge</button>
                                                </form>
                                            </div>
                                        )}
                                        {dados.filhosMenores > 0 && (
                                            <div className='blocosTitular'>
                                                <h1>Filhos</h1>
                                            </div>
                                        )}
                                    </div>
                                    <form onSubmit={conferirDocAcampado}>
                                        <select
                                            value={conferidoDoc}
                                            onChange={(e) => setConferidoDoc(e.target.value)}
                                        >
                                            <option></option>
                                            <option value={true}>Conferido</option>
                                        </select>
                                        <button>Enviar Documentos Conferidos</button>
                                    </form>
                                </div>
                            }
                        </>
                    }
                    <button className='buttonSair' onClick={() => mudarTela('/DashboardCoordenadores')}>Sair</button>
                </div>
            </div>
        )
    }
}
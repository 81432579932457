import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../../../Components/Contexts/AuthContext'
import apiLocal from '../../../apiLocal/apiLocal'
import NAutorizado from '../../../imagens/nautorizado.png'
import './vistoriaRoot.estilo.scss'
import { toast } from 'react-toastify'


export default function VistoriaRoot() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)

    const [coordenador, setCoordenador] = useState('')
    const [grupo, setGrupo] = useState('')

    const [dados, setDados] = useState([''])

    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                const grupoT = localStorage.getItem('@GrupoNome')
                setCoordenador(JSON.parse(cordT))
                setGrupo(JSON.parse(grupoT))
            }
            buscaDados()
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function listarAcampados() {
            const resposta = await apiLocal.get('/ListarAcampadosVistoria', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setDados(resposta.data)
        }
        listarAcampados()
        // eslint-disable-next-line
    }, [dados])

    async function mudarPendente(id) {
        await apiLocal.put('/MudarAcampadoPendenteRoot', {
            id
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    async function mudarInativo(id) {
        await apiLocal.put('/MudarAcampadoInativoRoot', {
            id
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
    async function mudarAtivo(id) {
        try {
            await apiLocal.put('/MudarAcampadoAtivoRoot', {
                id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            toast.success('Ativado Com Sucesso', {
                toastId: 'ToastId'
            })
        } catch (err) {
            toast.error('Não Existe Agendamento de Vistoria', {
                toastId: 'ToastId'
            })
        }
    }

    return (
        <div>
            <div className='conteinerVistoriaRootGeral'>
                {coordenador === false || (grupo !== 'ROOT') ?
                    <img src={NAutorizado} alt="" />
                    :
                    <div className='tituloVistoriaRoot'>
                        <h1>Vistoria Geral Root</h1>
                        <table className='tabelaVistoriaRoot'>
                            <thead>
                                <tr key="">
                                    <th>Titular</th>
                                    <th>Cadastro</th>
                                    <th>Modificação</th>
                                    <th>Rua</th>
                                    <th>Rancho</th>
                                    <th>Situação</th>
                                    <th>Documentos</th>
                                    <th>Ação</th>
                                </tr>
                                {dados.map((item) => {
                                    const formatDateToBrazilian = (isoDate) => {
                                        const date = new Date(isoDate);
                                        return date.toLocaleDateString('pt-BR');
                                    }
                                    const createDate = item.create_at
                                    const updateDate = item.update_at
                                    return (
                                        <tr key="">
                                            <>
                                                {(item.conferidoDoc === true && item.rua !== null ) && (
                                                    <>
                                                        <td>{item.nome}</td>
                                                        <td>{formatDateToBrazilian(createDate)}</td>
                                                        <td>{formatDateToBrazilian(updateDate)}</td>
                                                        <td>{item.rua}</td>
                                                        <td>{item.numeroRancho}</td>
                                                        <td>{item.status}</td>
                                                        <td>{item.conferidoDoc === true && (
                                                            <>
                                                                <span>SIM</span>
                                                            </>
                                                        )}</td>
                                                        <td><button className='botaoPendenteRoot' onClick={() => mudarPendente(item.id)}>Pendente</button>
                                                            <button className='botaoInativoRoot' onClick={() => mudarInativo(item.id)}>Inativar</button>
                                                            <button className='botaoAtivoRoot' onClick={() => mudarAtivo(item.id)}>Ativar</button></td>
                                                    </>
                                                )}
                                            </>
                                        </tr>
                                    )
                                })}
                            </thead>
                        </table>
                    </div>
                }
            </div>
        </div>
    )
}
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import FaixaHeader from '../Components/FaixaHeader'
import Cabecalho from '../Components/Cabecalho'
import Rodape from '../Components/Rodape'

import Dashboard from '../Dashboard'
import VisualizarDadosAcampado from '../VisualizarDadosAcampado'
import DashboardCoordenadores from '../DashboardsCoordenadores'

import CadastroAcampadosConjuge from '../CadastroAcampadosConjuge'
import CadastroAcampadosFilhos from '../CadastroAcampadosFilhos'

import CoordAdministracao from '../DashboardsCoordenadores/Administracao'
import CoordFinanceiro from '../DashboardsCoordenadores/Financeiro'
import ConferirAcampado from '../DashboardsCoordenadores/Administracao/ConferirAcampado'
import AcampadosInativos from '../DashboardsCoordenadores/Administracao/AcampadosInativos'
import AcampadosAtivos from '../DashboardsCoordenadores/Administracao/AcampadosAtivos'
import AcampadoPendente from './../DashboardsCoordenadores/Administracao/AcampadoPendente/index';
import RuasRanchos from '../DashboardsCoordenadores/Administracao/RuasRanchos'

import DoacoesDinheiro from '../Doacoes/Dinheiro'
import DoarDinheiro from '../Doacoes/Dinheiro/DoarDinheiro'
import DoacoesFeitasDinheiro from '../DashboardsCoordenadores/Financeiro/DoacoesFeitas'
import EditarDoacoesDinheiro from '../Doacoes/EditarDoacoesDinheiro'

import DashboardRootAdm from '../DashboardsCoordenadores/root'
import VistoriaRoot from '../DashboardsCoordenadores/root/Vistoria'
import SolicitacaoVistoria from '../DashboardsCoordenadores/root/SolicitacaoVistoria'
import GerenciaRoot from '../DashboardsCoordenadores/root/Gerencia'

import RelatorioIncra from '../DashboardsCoordenadores/root/RelatorioIncra'

export default function Autenticado() {
    return (
        <BrowserRouter>
            <FaixaHeader />
            <Cabecalho />
            <Routes>
                <Route path='/' element={<Dashboard />} />
                <Route path='/DasboardRootADM' element={<DashboardRootAdm />} />
                <Route path='/VistoriaRoot' element={<VistoriaRoot />} />
                <Route path='/SolicitacaoVistoriaRoot' element={<SolicitacaoVistoria />} />
                <Route path='/RuasRanchos' element={<RuasRanchos />} />
                <Route path='/DashboardCoordenadores' element={<DashboardCoordenadores />} />
                <Route path='/CadastroAcampadosConjuge' element={<CadastroAcampadosConjuge />} />
                <Route path='/CoordAdministracao' element={<CoordAdministracao />} />
                <Route path='/CoordFinanceiro' element={<CoordFinanceiro />} />
                <Route path='/ConferirAcampado/:id' element={<ConferirAcampado />} />
                <Route path='/VisualizarDadosAcampado' element={<VisualizarDadosAcampado />} />
                <Route path='/CadastroAcampadosFilhos' element={<CadastroAcampadosFilhos />} />
                <Route path='/AcampadosAtivos' element={<AcampadosAtivos />} />
                <Route path='/AcampadoPendente' element={<AcampadoPendente />} />
                <Route path='/AcampadosInativos' element={<AcampadosInativos />} />
                <Route path='/DoacoesDinheiro' element={<DoacoesDinheiro />} />
                <Route path='/DoarDinheiro' element={<DoarDinheiro />} />
                <Route path='/DoacoesFeitasDinheiro' element={<DoacoesFeitasDinheiro />} />
                <Route path='/EditarDoacoesDinheiro/:id' element={<EditarDoacoesDinheiro />} />
                <Route path='/GerenciaRoot' element={<GerenciaRoot />} />
                <Route path='/RelatorioIncra' element={<RelatorioIncra />} />

                <Route path='*' element={<Dashboard />} />
            </Routes>
            <Rodape />
        </BrowserRouter>
    )
}
import React, { useContext, useState } from 'react'
import { AuthContexts } from '../../../Components/Contexts/AuthContext'
import CurrencyInput from 'react-currency-input-field'
import { useNavigate } from 'react-router-dom'
import apiLocal from '../../../apiLocal/apiLocal'
import { toast } from 'react-toastify'

import './doarDinheiro.estilo.scss'

export default function DoarDinheiro() {

    const navegar = useNavigate()

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()
    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)
    const iId = localStorage.getItem('@Id')
    const id = JSON.parse(iId)

    const [valorDoado, setValorDoado] = useState('')
    const [dataDoacao, setDataDoacao] = useState('')
    const [finalidadeDoacao, setFinalidadeDoacao] = useState('')

    async function enviarDoacao(e) {
        const valorDoacao = valorDoado.replace(/,/g, ".")
        try {
            e.preventDefault()
            const resposta = await apiLocal.post('/DoacaoDinheiro', {
                id,
                valorDoacao,
                dataDoacao,
                finalidadeDoacao,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            toast.success(resposta.data.dados, {
                toastId: 'ToastId'
            })
            navegar('/')
        } catch (err) {

        }
    }

    const listaSelect = [
        { id: 'Doação Geral', nome: 'Doação Geral' },
        { id: 'Doação Manutenção', nome: 'Doação Manutenção' },
        { id: 'Doação Viagem', nome: 'Doação Viagem' },
    ]


    return (
        <div className='conteinerGeralDoarDinheiro'>
            <h1>Doação Voluntária em Dinheiro</h1>
            <div className='conteinerFormDoarDinheiro'>
                <form onSubmit={enviarDoacao}>
                    <span>Exemplo: para doar R$ 10,00 - digite 1000</span>
                    <CurrencyInput
                        placeholder="Digite o Valor Doado"
                        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                        disableGroupSeparators={true}
                        fixedDecimalLength={2}
                        onValueChange={(value) => setValorDoado(value)}
                    />
                    <input
                        type="date"
                        placeholder='Digite a Data da Doação'
                        value={dataDoacao}
                        onChange={(e) => setDataDoacao(e.target.value)}
                    />
                    <select
                        value={finalidadeDoacao}
                        onChange={(e) => setFinalidadeDoacao(e.target.value)}
                    >
                        <option value="" key="">Selecione Finalidade</option>
                        {listaSelect.map((item) => {
                            return (
                                <option value={item.id} key="">{item.nome}</option>
                            )
                        })}
                    </select>
                    <button type='submit'>Enviar</button>
                </form>
            </div>

        </div>
    )
}
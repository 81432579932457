import React, { useContext, useEffect, useState } from 'react'
import { AuthContexts } from '../../../Components/Contexts/AuthContext'
import './doacoesFeitasDinheiro.estilo.scss'
import apiLocal from '../../../apiLocal/apiLocal'
import { toast } from 'react-toastify'


export default function DoacoesFeitasDinheiro() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()
    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)

    const [dados, setDados] = useState([''])


    useEffect(() => {
        async function listarDoacoesDinheiro() {
            const resposta = await apiLocal.get('/ListarDoacaoDinheiro', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setDados(resposta.data)
        }
        listarDoacoesDinheiro()
        // eslint-disable-next-line
    }, [dados])

    async function mudarAtivo(id) {
       try {
        const resposta = await apiLocal.post('/ConferenciaDoacaoDinheiro', {
            id
        },{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        toast.success(resposta.data.dados, {
            toastId: 'ToastId'
        })
        
       } catch (err) {
        console.log(err)
        
       }

    }

    return (
        <div className='conteinerGeralDoacaoesFeitasDinheiro'>
            <h1>Doações Voluntárias Feitas em Dinheiro</h1>
            <div className='dadosTabelasDoacoesDinheiro'>
                <table className='dadosTabelas'>
                    <thead>
                        {dados.length === 0 ?
                            <h2>Sem Dados</h2>
                            :
                            <>
                                <tr key="">
                                    <th>Nome</th>
                                    <th>Data Doação</th>
                                    <th>Valor Doação</th>
                                    <th>Finalidade Doação</th>
                                    <th>Status Doação</th>
                                    <th>Ação</th>
                                </tr>
                                {dados.map((item) => {
                                    const formatDateToBrazilian = (isoDate) => {
                                        const date = new Date(isoDate);
                                        return date.toLocaleDateString('pt-BR');
                                    }
                                    const createDate = item.dataDoacao
                                    return (
                                        <>
                                            <tr key={item.id}>
                                                <>
                                                    {item.length !== 0 && (
                                                        <>
                                                            <td>{item.doacoDinheiro.nome}</td>
                                                            <td>{formatDateToBrazilian(createDate)}</td>
                                                            <td>R$ {item.valorDoacao.replace(".", ",")}</td>
                                                            <td>{item.finalidadeDoacao}</td>
                                                            {item.status === false ? <td>Pendente</td> : <td>Conferido</td>}
                                                            <td>
                                                                {item.status === true ?
                                                                <button disabled className='botaoAtivoRootDisabled' onClick={() => mudarAtivo(item.id)}>Conferido</button>
                                                                :
                                                                <button className='botaoAtivoRoot' onClick={() => mudarAtivo(item.id)}>Conferido</button>
                                                            }
                                                            </td>
                                                        </>
                                                    )}
                                                </>
                                            </tr>
                                        </>
                                    )
                                })}
                            </>
                        }
                    </thead>
                </table>
            </div>
        </div>
    )
}
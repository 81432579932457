import React, { useContext, useEffect, useState } from 'react'
import { AuthContexts } from '../../Components/Contexts/AuthContext'
import { useParams, useNavigate } from 'react-router-dom'
import CurrencyInput from 'react-currency-input-field'
import './editarDoacoesDinheiro.estilo.scss'
import apiLocal from '../../apiLocal/apiLocal'
import { toast } from 'react-toastify'


export default function EditarDoacoesDinheiro() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()
    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)

    const navegar = useNavigate()

    const { id } = useParams()

    const [dados, setDados] = useState('')
    const [valorDoado, setValorDoado] = useState('')

    useEffect(() => {
        async function listarDoacoesDinheiro() {
            const resposta = await apiLocal.get(`/ListarDoacaoDinheiroEditar/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setDados(resposta.data)
            setValorDoado(resposta.data.valorDoacao)
        }
        listarDoacoesDinheiro()
        // eslint-disable-next-line
    }, [])

    async function enviarNovaDoacao(e) {
        e.preventDefault()
        const valorDoacao = valorDoado.replace(/,/g, ".")
        try {
            const resposta = await apiLocal.put('/EditarDoacaoDinheiro', {
                id,
                valorDoacao
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            navegar('/')
            toast.success(resposta.data.dados, {
                toastId: 'ToastId'
            })
        } catch (err) {

        }
    }

    async function excluirDoacao(e) {
        e.preventDefault()
        try {
            await apiLocal.delete(`/ExcluirDoacaoDinheiro/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            navegar('/')
            toast.success('Doação Apagada com Sucesso', {
                toastId: 'ToastId'
            })

        } catch (err) {


        }
    }

    const formatDateToBrazilian = (isoDate) => {
        const date = new Date(isoDate);
        return date.toLocaleDateString('pt-BR');
    }
    const createDate = dados.dataDoacao
    const novaDataDoacao = formatDateToBrazilian(createDate)


    return (
        <div className='conteinerGeralEditarDoacaoesDinheiro'>
            <h1>Editar Doações em Dinheiro</h1>
            <div className='conteinerBotoesEditarDoacaoDinheiro'>
                {dados.length !== 0 && (
                    <>
                        <form onSubmit={enviarNovaDoacao}>
                            <span>
                                Data Doação: {novaDataDoacao}
                            </span>
                            <span>
                                Valor Doação: R$ {dados.valorDoacao.replace(".", ",")}
                            </span>
                            <span>Exemplo: para doar R$ 10,00 - digite 1000</span>
                            <div className='conteinerInput'>
                                <span>Valor Doação Corrigido: </span>
                                <CurrencyInput
                                    placeholder="Digite o Novo Valor "
                                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                    disableGroupSeparators={true}
                                    fixedDecimalLength={2}
                                    onValueChange={(value) => setValorDoado(value)}
                                />
                            </div>
                            <button type='submit'>Enviar</button>
                        </form>
                    </>
                )}
            </div>
            <button onClick={excluirDoacao} type='submit'>Excluir</button>
        </div>
    )
}
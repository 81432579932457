import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../Components/Contexts/AuthContext'
import { Link } from 'react-router-dom'
import './dashboardCoordenadores.estilo.scss'

import Adm from '../imagens/adm.png'
import Financeiro from '../imagens/financeiro.png'
import RootLogo from '../imagens/root.png'
import apiLocal from '../apiLocal/apiLocal'


export default function DashboardCoordenadores() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)

    const [coordenador, setCoordenador] = useState('')
    const [total, setTotal] = useState('')
    const [ativos, setAtivos] = useState('')
    const [inativos, setInativos] = useState('')
    const [pendentes, setPendentes] = useState('')

    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                setCoordenador(JSON.parse(cordT))
            }
            buscaDados()
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        try {
            async function acampadosTotal() {
                const resposta = await apiLocal.get('/ContarAcampadosTotal', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                setTotal(resposta.data._all)
            }
            acampadosTotal()
        } catch (error) {

        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        try {
            async function acampadosAtivos() {
                const resposta = await apiLocal.get('/ContarAcampadosAtivos', {
                    headers: {
                        // eslint-disable-next-line
                        Authorization: `Bearer ${token}`
                    }
                })
                setAtivos(resposta.data._all)
            }
            acampadosAtivos()
        } catch (error) {

        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        try {
            async function acampadosPendentes() {
                const resposta = await apiLocal.get('/ContarAcampadosPendentes', {
                    headers: {
                        // eslint-disable-next-line
                        Authorization: `Bearer ${token}`
                    }
                })
                setPendentes(resposta.data._all)
            }
            acampadosPendentes()
        } catch (error) {

        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        try {
            async function acampadosInativos() {
                const resposta = await apiLocal.get('/ContarAcampadosInativos', {
                    headers: {
                        // eslint-disable-next-line
                        Authorization: `Bearer ${token}`
                    }
                })
                setInativos(resposta.data._all)
            }
            acampadosInativos()
        } catch (error) {

        }
        // eslint-disable-next-line
    }, [])


    return (
        <div>
            <div className='conteinerGeralDasboardCoordenadores'>
                {coordenador === false ?
                    <>
                        <div className='tituloDashboardCoordenador'>
                            <h1>Acesso Não Autorizado</h1>
                        </div>
                    </>
                    :
                    <>
                        <div className='tituloDashboardCoordenador'>
                            <h1>DashBoard Coordenadores</h1>
                            <div className='totalAcampados'>
                                <h2>Total Cadastrados: {total}</h2>
                                <h2>Total Ativos: {ativos}</h2>
                                <h2>Total Pendentes: {pendentes}</h2>
                                <h2>Total Inativos: {inativos}</h2>
                            </div>
                        </div>
                        <div className='corpoDashboardCoordenadores'>
                            <Link to='/CoordAdministracao'><img src={Adm} alt="ADM" /></Link>
                            <Link to='/CoordFinanceiro'><img src={Financeiro} alt="Financeiro" /></Link>
                            <Link to='/DasboardRootADM'><img src={RootLogo} alt="Root" /></Link>

                        </div>
                    </>
                }
            </div>
        </div>
    )
}
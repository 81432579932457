import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../../Components/Contexts/AuthContext'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import { toast } from 'react-toastify'
import { IMaskInput } from 'react-imask'
import { cpf } from 'cpf-cnpj-validator'
import NAutorizado from '../../imagens/nautorizado.png'
import './estilo.coordenadoresAdministracao.scss'
import apiLocal from '../../apiLocal/apiLocal'

export default function CoordAdministracao() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)

    const [coordenador, setCoordenador] = useState('')
    const [grupo, setGrupo] = useState('')
    const [modalAberto, setModalAberto] = useState(false)

    const [cpfMask, setCpfMask] = useState('')

    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                const grupoT = localStorage.getItem('@GrupoNome')
                setCoordenador(JSON.parse(cordT))
                setGrupo(JSON.parse(grupoT))
            }
            buscaDados()
        } catch (err) {

        }
    }, [])

    async function abriModal() {
        setCpfMask('')
        setModalAberto(true)
    }

    async function fecharModal() {
        setModalAberto(false)
    }

    async function alterarSenha(e) {
        try {
            e.preventDefault()
            if (!cpfMask) {
                toast.warning('Existem Campos Em branco', {
                    toastId: 'ToastID'
                })
                return
            }
            const cpfValido = cpf.isValid(cpfMask)
            if (!cpfValido) {
                toast.error('CPF Inválido', {
                    toastId: 'ToastID'
                })
                return
            }

            let cpfV = cpfMask.match(/\d/g).join("")
            const password = '@TerraPrometida2024'
            await apiLocal.put('/AlterarSenhaTitular', {
                cpfV,
                password
            }, {
                headers: {
                    // eslint-disable-next-line
                    Authorization: `Bearer ${token}`
                }
            })
            toast.success('Senha Alterada para a Padrão', {
                toastId: 'ToastId'
            })
            setModalAberto(false)
        } catch (err) {
            toast.error('Erro ao Comunicar com o Servidor', {
                toastId: 'ToastId'
            })
        }
    }

    return (
        <div>
            <div className='containerCoordenadorAdministracaoGeral'>
                {coordenador === false || (grupo !== 'ADM' && grupo !== 'ROOT') ?
                    <img src={NAutorizado} alt="" />
                    :
                    <div>
                        <div className='tituloCoordenadorAdministracao'>
                            <h1>DashBoard Administração</h1>
                        </div>
                        <div className='acessosAcampadosCoordenadorAdministracao'>
                            <div className='acessosAcampadosCoordenadorAdministracaoInt'>
                                {/*<Link to='/CadastroAcampados'>Cadastro Acampado</Link>*/}
                                <Link to='/AcampadoPendente'>Pendente</Link>
                                <Link to='/AcampadosAtivos'>Ativos</Link>
                                <button className='botaoSenhaAcampado' onClick={abriModal}>Alterar Senha</button>
                                <Modal
                                    className="Modal"
                                    isOpen={modalAberto}
                                >
                                    <div className='conteinerModal'>
                                        <h1>Alterar Senha do Titular</h1>
                                        <h2>Senha Padrão: @TerraPrometida2024</h2>
                                        <form>
                                            <IMaskInput
                                                type="text"
                                                mask='000.000.000-00'
                                                placeholder='Digite o CPF do Acampado'
                                                value={cpfMask}
                                                onChange={(e) => setCpfMask(e.target.value)}
                                            />
                                            <button onClick={alterarSenha}>Enviar</button>
                                        </form>
                                        <button className='botaoSairModal' onClick={fecharModal}>Fechar</button>
                                    </div>
                                </Modal>
                            </div>
                            <div className='botoesBaixo'>
                                <Link to='/AcampadosInativos'>Acampados Inativos</Link>
                                <Link className='ranchosRuas' to='/RuasRanchos'>Ruas e Ranchos</Link>
                                <Link className='solicitacaoVistoria' to='/SolicitacaoVistoriaRoot'>Agendamentos Vistorias</Link>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
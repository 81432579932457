import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../../Components/Contexts/AuthContext'
import { Link } from 'react-router-dom'
import NAutorizado from '../../imagens/nautorizado.png'
import './financeiro.estilo.scss'

export default function CoordFinanceiro() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const [coordenador, setCoordenador] = useState('')
    const [grupo, setGrupo] = useState('')

    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                const grupoT = localStorage.getItem('@GrupoNome')
                setCoordenador(JSON.parse(cordT))
                setGrupo(JSON.parse(grupoT))
            }
            buscaDados()
        } catch (err) {

        }
    }, [])

    return (
        <div className='conteinerFinanceiroGeral'>
            {coordenador === false || (grupo !== 'Financeiro' && grupo !== 'ROOT') ?
                <img src={NAutorizado} alt="" />
                :
                <>                   
                        <div className='tituloFinanceiroGeral'>
                            <h1>Dashboard Financeiro</h1>
                        </div>
                        <div className='acessoFinanceiroGeral'>
                            <Link to='/DoacoesFeitasDinheiro'>Doações Feitas</Link>
                        </div>
                    
                </>
            }
        </div>
    )
}